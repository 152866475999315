import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { find, propEq } from 'ramda'

const { Types, Creators } = createActions({
  siteManagerRequest: ['preSelect'],
  siteManagerSuccess: ['data'],
  siteManagerFailure: null,

  siteManagerRequestCurrent: [],
  siteManagerSetCurrent: ['siteId'],
  siteManagerClearCurrent: null,
  siteManagerToggleMultiple: ['siteId'],

  siteManagerSetParent: ['siteId'],
  siteManagerClearParent: null,

  siteManagerUsersRequest: ['siteId'],
  siteManagerUsersSuccess: ['data'],
  siteManagerUsersFailure: null,

  siteManagerDevicesRequest: ['siteId'],
  siteManagerDevicesSuccess: ['data'],
  siteManagerDevicesFailure: null,

  siteManagerSetCurrentUser: ['userId'],
  siteManagerSetCurrentDevice: ['deviceData'],

  siteManagerCreateSite: ['data', 'parentGuid'],
  siteManagerUpdateRequest: ['data', 'siteId', 'parentGuid'],
  siteManagerDeleteRequest: ['siteId'],

  siteManagerGenericSuccess: null,
  siteManagerGenericFailure: null,
  siteManagerDeleteSuccess: null,

  siteManagerRemoveUserRequest: ['siteId', 'userId'],

  siteManagerRootSitesRequest: null,
  siteManagerRootSitesSuccess: ['rootSites'],
  siteManagerRootSitesFailure: null,

  siteManagerRequestReports: null,
  siteManagerRequestReportsSuccess: ['data'],
  siteManagerRequestReportsFailure: null,

  siteManagerOrgSettings:['siteId'],
  siteManagerOrgSettingsSuccess:['data'],
  siteManagerOrgSettingsFailure:null,

  siteManagerUpdateOrgSettings:['siteId', 'automaticSettings'],
  siteManagerUpdateOrgSettingsSuccess:null,
  siteManagerUpdateOrgSettingsFailure:null
})

export const SiteManagerTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  loading: false,
  loadingUsers: false,
  loadingDevices: false,
  error: false,
  errorUsers: false,
  errorDevices: false,
  sites: {},
  sitesById: [],
  siteId: null,
  currentSite: '',
  parentSite: '',
  selectedSites: [],
  currentUsers: [],
  currentDevices: [],
  currentUser: {},
  currentDevice: {},
  rootSitesError: null,
  rootSitesById: [],
  rootSites: [],
  sitesByIdWithReports: [],
  preSelect: true,
  loading:false,
  OrgRecommendationsSettings:{
    orgId:null,
    orgName:null,
    features:{
      recommendationsEnabled:false,
      automaticRecommendationsEnabled:false
    }
  }
})

/* ------------- Reducers ------------- */

export const request = (state, { preSelect }) =>
  state.merge({ loading: true, error: false, preSelect: preSelect })

export const success = (state, { data }) => {
  const fullData = data.reduce((obj, item) => {
    const guid = item['organizationalUnitIdentifier']['organizationalUnitGuid'] || ''
    obj[guid] = { ...item }
    return obj
  }, {})

  const sitesById = data.map(s => ({
    name: s['organizationalUnitHierarchy'] || s['organizationalUnitIdentifier']['organizationalUnitName'] || '',
    guid: s['organizationalUnitIdentifier']['organizationalUnitGuid'] || ''
  }))

  sitesById.sort((a, b) => (a.name && a.name.localeCompare(b.name)))

  const [firstSite] = sitesById
  const selected = state.getIn(['currentSite'], firstSite ? firstSite.guid : '')
  // NOTE: getIn() default seem to not be working properly all the time

  const currentSite = state.getIn(['preSelect'])
    ? selected
      ? selected
      : (firstSite ? firstSite.guid : '')
    : ''

  return state.merge({
    loading: false,
    sites: fullData,
    sitesById: sitesById,
    currentSite: currentSite,
    parentSite: currentSite,
    error: false,
    preSelect: true
  })
}

export const failure = state =>
  state.merge({ error: true, loading: false })

export const setCurrentSite = (state, { siteId }) =>
  state.merge({ currentSite: siteId,
    parentSite: siteId || state.getIn(['parentSite']),
    currentDevices: [],
    currentUsers: []
  })

export const requestCurrentSite = state => state

export const usersRequest = state =>
  state.merge({ loadingUsers: true, currentUsers: [], errorUsers: false })

export const usersSuccess = (state, { data }) =>
  state.merge({ currentUsers: data, loadingUsers: false, errorUsers: false })

export const usersFailure = (state) =>
  state.merge({ currentUsers: [], loadingUsers: false, errorUsers: true })

export const devicesRequest = state =>
  state.merge({ loadingDevices: true, currentDevices: [], errorDevices: false })

export const devicesSuccess = (state, { data }) =>
  state.merge({ currentDevices: data, loadingDevices: false, errorDevices: false })

export const devicesFailure = (state) =>
  state.merge({ currentDevices: [], loadingDevices: false, errorDevices: true })

export const setUser = (state, { userId }) =>
  state.merge({ currentUser: find(propEq('id', userId))(state.getIn(['currentUsers'])) })

export const setDevice = (state, { deviceData }) =>
  state.merge({ currentDevice: deviceData })

export const createRequest = state =>
  state.merge({ loading: true, error: false })

export const genericSuccess = state =>
  state.merge({ loading: false, error: false })

export const genericFailure = state =>
  state.merge({ loading: false, error: true })

export const deleteSuccess = state =>
  state.merge({
    loading: false,
    error: false,
    currentSite: state.parentSite && state.parentSite !== state.currentSite
      ? state.parentSite
      : ''
  })

export const clearCurrent = state =>
  state.merge({ currentSite: '' })

export const updateRequest = state =>
  state.merge({ loading: true, error: false })

export const setParentSite = (state, { siteId }) =>
  state.merge({ parentSite: siteId })

export const clearParent = state =>
  state.merge({ parentSite: '' })

export const removeUserRequest = state =>
  state.merge({ loading: true, error: false })

export const rootSitesRequest = state =>
  state.merge({ loading: true, rootSitesError: false })

export const rootSitesSuccess = (state, { rootSites }) => {
  const sitesById = rootSites.map(s => ({
    text: s['organizationalUnitHierarchy'] || s['organizationalUnitIdentifier']['organizationalUnitName'] || '',
    value: s['organizationalUnitIdentifier']['organizationalUnitGuid'] || ''
  }))

  sitesById.sort((a, b) => (a.text && a.text.localeCompare(b.text)))

  return state.merge({ loading: false, rootSites: rootSites, rootSitesError: false, rootSitesById: sitesById })
}

export const rootSitesFailure = state =>
  state.merge({ loading: false, rootSitesError: true })

const toggleSite = (state, { siteId }) => {
  const sites = state.getIn(['selectedSites'])
  const currentSites = sites.indexOf(siteId) === -1
    ? [...sites, siteId]
    : sites.filter(x => x !== siteId)

  return state.merge({ selectedSites: currentSites })
}

export const sitesWithReportsSuccess = (state, { data }) => {

  const sitesById = data.map(s => ({
    name: s['organizationalUnitHierarchy'] || s['organizationalUnitIdentifier']['organizationalUnitName'] || '',
    guid: s['organizationalUnitIdentifier']['organizationalUnitGuid'] || ''
  }))

  sitesById.sort((a, b) => (a.name && a.name.localeCompare(b.name)))

  return state.merge({
    loading: false,
    sitesByIdWithReports: sitesById,
    sitesByIdWithReportsError: false
  })
}

export const OrgSettingsRequest = state =>
  state.merge({ loading: true, error: false })

export const OrgSettingsSuccess = (state, {data}) => 
  state.merge({ loading:false, error: false, OrgRecommendationsSettings:data})

export const OrgSettingsFailure = (state, {data}) => 
  state.merge({ loading:false, error: true})



export const UpdateOrgSettingsRequest = state =>
  state.merge({ loading: true, error: false })

export const UpdateOrgSettingsSuccess = state => 
  state.merge({ loading:false, error: false})

export const UpdateOrgSettingsFailure = state => 
  state.merge({ loading:false, error: true})

/* --------Types-------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SITE_MANAGER_REQUEST]: request,
  [Types.SITE_MANAGER_SUCCESS]: success,
  [Types.SITE_MANAGER_FAILURE]: failure,
  [Types.SITE_MANAGER_REQUEST_CURRENT]: requestCurrentSite,
  [Types.SITE_MANAGER_SET_CURRENT]: setCurrentSite,
  [Types.SITE_MANAGER_TOGGLE_MULTIPLE]: toggleSite,
  [Types.SITE_MANAGER_USERS_REQUEST]: usersRequest,
  [Types.SITE_MANAGER_USERS_SUCCESS]: usersSuccess,
  [Types.SITE_MANAGER_USERS_FAILURE]: usersFailure,
  [Types.SITE_MANAGER_DEVICES_REQUEST]: devicesRequest,
  [Types.SITE_MANAGER_DEVICES_SUCCESS]: devicesSuccess,
  [Types.SITE_MANAGER_DEVICES_FAILURE]: devicesFailure,
  [Types.SITE_MANAGER_SET_CURRENT_USER]: setUser,
  [Types.SITE_MANAGER_SET_CURRENT_DEVICE]: setDevice,
  [Types.SITE_MANAGER_CREATE_SITE]: createRequest,
  [Types.SITE_MANAGER_GENERIC_SUCCESS]: genericSuccess,
  [Types.SITE_MANAGER_GENERIC_FAILURE]: genericFailure,
  [Types.SITE_MANAGER_DELETE_SUCCESS]: deleteSuccess,
  [Types.SITE_MANAGER_CLEAR_CURRENT]: clearCurrent,
  [Types.SITE_MANAGER_UPDATE_REQUEST]: updateRequest,
  [Types.SITE_MANAGER_SET_PARENT]: setParentSite,
  [Types.SITE_MANAGER_CLEAR_PARENT]: clearParent,
  [Types.SITE_MANAGER_DELETE_REQUEST]: updateRequest,
  [Types.SITE_MANAGER_REMOVE_USER_REQUEST]: removeUserRequest,
  [Types.SITE_MANAGER_ROOT_SITES_REQUEST]: rootSitesRequest,
  [Types.SITE_MANAGER_ROOT_SITES_SUCCESS]: rootSitesSuccess,
  [Types.SITE_MANAGER_ROOT_SITES_FAILURE]: rootSitesFailure,
  [Types.SITE_MANAGER_REQUEST_REPORTS]: request,
  [Types.SITE_MANAGER_REQUEST_REPORTS_SUCCESS]: sitesWithReportsSuccess,
  [Types.SITE_MANAGER_REQUEST_REPORTS_FAILURE]: failure,
  [Types.SITE_MANAGER_ORG_SETTINGS]:OrgSettingsRequest,
  [Types.SITE_MANAGER_ORG_SETTINGS_SUCCESS]:OrgSettingsSuccess,
  [Types.SITE_MANAGER_ORG_SETTINGS_FAILURE]:OrgSettingsFailure,
  [Types.SITE_MANAGER_UPDATE_ORG_SETTINGS]: UpdateOrgSettingsRequest,
  [Types.SITE_MANAGER_UPDATE_ORG_SETTINGS_SUCCESS]:UpdateOrgSettingsSuccess,
  [Types.SITE_MANAGER_UPDATE_ORG_SETTINGS_FAILURE]:UpdateOrgSettingsFailure
})